<template>
  <div class="fixed bottom-7 right-7 z-20 hidden text-right lg:block">
    <div
      v-if="!collapsed && memoId !== ''"
      class="mb-3 h-64 w-64 bg-yellow-100 shadow-xl"
    >
      <textarea
        v-model="body"
        class="h-full w-full resize-none bg-transparent px-3 py-2 text-base text-black outline-none"
        @keydown="scheduleSave"
      />
    </div>

    <div class="flex items-center">
      <select
        v-if="!collapsed"
        v-model="memoId"
        class="mr-3 grow appearance-none border border-stone-300 bg-white px-1 text-base text-stone-600 outline-none hover:border-black hover:text-black dark:border-gray-600 dark:bg-gray-800 dark:hover:border-gray-400 dark:hover:text-gray-400"
      >
        <option value="">Selecteer memo</option>

        <optgroup label="Memo's">
          <option
            v-for="memo in data.memos"
            :key="`memo-option-${memo.id}`"
            :value="memo.id"
          >
            {{ memo.title }}
          </option>
        </optgroup>

        <option value="NEW">+ Nieuw memo</option>
      </select>

      <div
        class="h-14 w-14 cursor-pointer select-none rounded-full border-2 border-black bg-black pt-5 text-center text-sm leading-3 text-white dark:border-gray-800 dark:bg-gray-800"
        :class="{
          'bg-white !text-black dark:!bg-gray-700 dark:!text-gray-400':
            collapsed,
        }"
        @click="collapsed = !collapsed"
      >
        MEMO
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { reactive, ref, watch } from 'vue'
  import { useMutation, useQuery } from 'villus'

  import Memos from '../graphql/MemoTitles.graphql'
  import GetMemo from '../graphql/Memo.graphql'
  import CreateMemo from '../graphql/CreateMemo.graphql'
  import SaveMemo from '../graphql/SaveMemo.graphql'

  const collapsed = ref(true)
  const memoId = ref('')
  const body = ref('')
  const saveTimeout = ref(0)

  const { isDone, data } = useQuery({ query: Memos })

  watch(isDone, () => {
    if (data.value.memos.length) {
      memoId.value = data.value.memos[0].id
    }
  })

  const { execute: createMemo } = useMutation(CreateMemo)
  const variables = reactive({ id: memoId })
  const { execute: getMemo } = useQuery({
    query: GetMemo,
    variables,
    fetchOnMount: false,
    cachePolicy: 'network-only',
    paused: true,
  })

  watch(memoId, (id, oldId) => {
    if (id === 'NEW') {
      const title = prompt('Kies een titel')
      if (title) {
        createMemo({ title }).then((resp) => {
          const attrs = resp.data.createMemo
          data.value.memos.push(attrs)
          memoId.value = attrs.id
        })
      } else {
        memoId.value = oldId
      }
    } else {
      getMemo().then((resp) => (body.value = resp.data.memo.body))
    }
  })

  const { execute: save } = useMutation(SaveMemo)

  const doSave = () => {
    save({ id: memoId.value, body: body.value })
  }

  const scheduleSave = () => {
    clearTimeout(saveTimeout.value)
    saveTimeout.value = setTimeout(doSave, 1500)
  }
</script>
