<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable tailwindcss/no-custom-classname -->

<template>
  <span>
    <line-highlight v-if="isHighlighted" />

    <span
      :id="`line-${line.id}`"
      class="mr-2 cursor-pointer last:mr-0"
      :class="{
        '!shadow-favorite dark:!shadow-dark-favorite bg-yellow-100 !text-black dark:!bg-yellow-500':
          line.isFavorite && !isPinned && !isHovered,
        'shadow-pin dark:shadow-dark-pin !bg-slate-300 text-black dark:!bg-slate-400':
          isPinned,
        'shadow-hover dark:shadow-dark-hover bg-slate-200 text-black dark:bg-slate-500':
          isHovered,
        'shadow-notes dark:shadow-dark-notes bg-slate-100 text-black dark:bg-stone-800 dark:text-inherit':
          line.hasNotes && !isPinned && !isHovered,
        'after:content-[\'*\']': line.hasFootnotes,
      }"
      @mouseover="emit('set:line', line)"
      @pointerout="emit('unset:line')"
      @mousedown="emit('pin:line', line)"
      v-html="line.body"
    />

    <span v-if="!lastLine" style="position: fixed; font-size: 0px">&nbsp;</span>
  </span>
</template>

<script setup lang="ts">
  import { computed, PropType } from 'vue'
  import LineHighlight from './LineHighlight.vue'

  const emit = defineEmits(['pin:line', 'set:line', 'unset:line'])

  const props = defineProps({
    lastLine: {
      type: Boolean,
      default: () => false,
    },

    line: {
      type: Object as PropType<Line | LineGap>,
      required: true,
    },

    hoveredLines: {
      type: Array as PropType<Array<Line | LineGap>>,
      default: () => [],
    },

    pinnedLines: {
      type: Array as PropType<Array<Line | LineGap>>,
      default: () => [],
    },

    isHighlighted: {
      type: Boolean,
      default: () => false,
    },
  })

  const isPinned = computed(() => props.pinnedLines.indexOf(props.line) >= 0),
    isHovered = computed(() => props.hoveredLines.indexOf(props.line) >= 0)

  /* const padding = '3px',
    negPadding = '-3px',
    notedBg = '#eee',
    hoveredBg = '#d2e1e6',
    pinnedBg = '#aac9dd',
    favoritedBg = '#fff7ca' */
</script>
