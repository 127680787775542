<template>
  <blockquote class="border-l-4 border-l-stone-200 pl-3 dark:border-l-gray-800">
    <single-line
      v-for="(line, idx) in linesWithGaps"
      :key="`line-${line.idx}`"
      :last-line="idx + 1 === linesWithGaps.length"
      :line="line"
    />

    <router-link
      v-if="document"
      class="ml-1 whitespace-nowrap underline underline-offset-2"
      :to="{ name: 'document', hash: hash, params: { id: document.id } }"
      >Lees verder
    </router-link>
  </blockquote>
</template>

<script setup lang="ts">
  import SingleLine from '../components/SingleLine.vue'
  import { computed, PropType } from 'vue'

  const props = defineProps({
    document: {
      type: Object as PropType<Doc>,
      default: () => null,
    },

    lines: {
      type: Array as PropType<Line[]>,
      required: true,
    },
  })

  const range = (start: number, end: number) =>
    new Array(end - start + 1).fill(undefined).map((_, i) => i + start)

  const indices = computed(() => {
    return range(props.lines[0].idx, props.lines[props.lines.length - 1].idx)
  })

  const linesWithGaps = computed<Array<Line | LineGap>>(() => {
    let prevLine: Line | LineGap | null = null

    const allLines = indices.value.flatMap((idx) => {
      const line = props.lines.find((l) => l.idx === idx)
      let nextLine: Line | LineGap | null = null

      if (line) {
        nextLine = line
      } else if ((prevLine && prevLine.id === 'empty') || prevLine === null) {
        nextLine = null
      } else {
        nextLine = {
          idx: idx,
          body: '(…)',
          id: 'empty',
          unit: null,
          notes: [],
          footnotes: [],
          hasNotes: false,
          hasFootnotes: false,
          isFavorite: false,
        }
      }

      prevLine = nextLine
      return nextLine
    })

    const presentLines = allLines.filter((l) => l !== null)
    return presentLines as Line[]
  })

  const hash = computed(() => `#line-${props.lines[0].id}`)
</script>
