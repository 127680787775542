<template>
  <top-header
    :me="me"
    :is-collapsed="isCollapsed"
    :do-sync-readwise="doSyncReadwise"
    @update:height="headerHeight = $event"
  />

  <main
    class="mx-auto max-w-4xl overflow-x-hidden px-4 sm:px-8"
    :class="{ 'max-w-6xl': String(route.name) === 'document' }"
  >
    <router-view v-slot="{ Component }">
      <keep-alive :include="/BooksView|AccountView/">
        <component
          :is="Component"
          v-model:loadingTitle="loadingTitle"
          :header-height="headerHeight"
          :me="me"
          :labels="labels"
          @add:label="addLabel"
          @favorite:set="favoriteHandler"
          @update:label="updateLabel"
        />
      </keep-alive>
    </router-view>

    <memo-popover v-if="me" />
  </main>
</template>

<script lang="ts" setup>
  import { computed, nextTick, onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useQuery } from 'villus'
  import { useHead } from 'unhead'

  import TopHeader from './components/TopHeader.vue'
  import query from './graphql/Bootstrap.graphql'
  import MemoPopover from './components/MemoPopover.vue'

  const loadingTitle = ref(null)
  const isCollapsed = ref(false)
  const headerHeight = ref(0)
  const route = useRoute()
  const router = useRouter()

  const doSyncReadwise = ref(false)
  const favoriteHandler = () => {
    doSyncReadwise.value = true
    nextTick(() => (doSyncReadwise.value = false))
  }

  // GoatCounter on Vue Router-event
  router.afterEach(() => {
    const path = location.pathname + location.search + location.hash
    const title = document.title

    // Timeout to allow title-setting
    setTimeout(() => {
      window.goatcounter?.count({ path, title })
    }, 2000)
  })

  // Set Meta-title
  useHead({
    titleTemplate: (title?: string) =>
      !title ? 'Oepke Noordmans' : `${title} - Oepke Noordmans`,
  })

  // Bootstrap data

  const { data, isDone } = useQuery({ query })
  const me = computed<User | null>(() =>
    isDone.value && data.value ? data.value.me : null,
  )

  // Bootstrap labels

  const labels = computed<Label[]>(() => {
    if (isDone.value && data.value) {
      const labels: Label[] = data.value.labels

      labels.sort((a, b) => {
        const ta = a.title.toLowerCase(),
          tb = b.title.toLowerCase()

        if (ta < tb) {
          return -1
        }
        if (ta > tb) {
          return 1
        }
        return 0
      })

      return labels
    } else {
      return []
    }
  })

  const addLabel = (label: Label) => {
    data.value.labels.push(label)
  }

  const updateLabel = (label: UpdateLabel) => {
    const labelIdx = data.value.labels.findIndex(
      (l: Label) => l.id === label.id,
    )

    if (labelIdx !== null) {
      const curLabel = data.value.labels[labelIdx]
      curLabel.parentId = label.parentId
      curLabel.position = label.position
    }
  }

  onMounted(() => {
    window.onscroll = () => {
      isCollapsed.value = window.scrollY > headerHeight.value
    }
  })
</script>

<style lang="postcss">
  body {
    @apply dark:bg-gray-700 dark:text-gray-300;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'EB Garamond', serif;
    font-size: 19px;
  }
</style>
