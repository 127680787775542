<template>
  <div v-if="isDone" class="space-y-8">
    <article
      v-for="(item, idx) in data.news || []"
      :key="`news-item-${item.id}`"
      class="space-y-2 [&:not(:last-child)]:border-b-2 [&:not(:last-child)]:border-b-stone-300 [&:not(:last-child)]:pb-8 [&:not(:last-child)]:dark:border-b-gray-800"
    >
      <h3 class="text-xl font-bold">
        <router-link
          class="underline underline-offset-2"
          :to="{ name: 'page', params: { slug: item.slug } }"
        >
          {{ item.title }}
        </router-link>
      </h3>
      <div
        v-if="idx === 0"
        class="space-y-2 [&>h3]:font-bold [&>p>a]:underline [&>p>a]:underline-offset-2"
        v-html="item.body"
      ></div>
    </article>
  </div>

  <loading-view v-else />
</template>

<script lang="ts" setup>
  import { useQuery } from 'villus'
  import query from '../graphql/News.graphql'
  import LoadingView from './LoadingView.vue'

  const { data, isDone } = useQuery({ query })
</script>
