<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col gap-10 py-4 sm:flex-row-reverse sm:py-8">
    <section class="sm:w-7/12">
      <div class="sm:hidden">
        <photo-frame />
        <subscription-widget
          v-if="!me"
          class="mb-6 bg-stone-100 px-4 py-3 dark:bg-gray-600"
        />
      </div>

      <h2 class="mb-4 text-3xl font-bold">Nieuws</h2>
      <news-items />
    </section>

    <aside class="sm:w-5/12">
      <photo-frame class="-mt-3 hidden sm:block" />

      <div
        class="space-y-8 [&>*]:bg-stone-100 [&>*]:px-4 [&>*]:py-3 [&>*]:dark:bg-gray-600"
      >
        <subscription-widget v-if="!me" class="hidden sm:block" />

        <highlighted-document
          @update:loading-title="emit('update:loading-title', $event)"
        />

        <recent-notes />
      </div>
    </aside>
  </div>
</template>

<script lang="ts" setup>
  import { PropType } from 'vue'
  import { useHead } from 'unhead'

  import HighlightedDocument from '../components/HighlightedDocument.vue'
  import RecentNotes from '../components/RecentNotes.vue'
  import PhotoFrame from '../components/PhotoFrame.vue'
  import NewsItems from '../components/NewsItems.vue'
  import SubscriptionWidget from '../components/SubscriptionWidget.vue'

  useHead({ title: null })

  const emit = defineEmits(['update:loading-title'])

  defineProps({
    me: {
      type: Object as PropType<User>,
      default: () => null,
    },
  })
</script>
