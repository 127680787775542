<template>
  <form class="space-y-2" @submit.prevent="createSubscription">
    <h3 class="flex items-center space-x-2 text-xl font-bold">
      <span class="grow">In ontwikkeling</span>
      <span class="relative ml-auto flex h-3 w-3">
        <span
          class="animate-highlight-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"
        ></span>
        <span
          class="relative inline-flex h-3 w-3 rounded-full bg-sky-500"
        ></span>
      </span>
    </h3>

    <p class="text-base">
      De nieuwe website van de Stichting Noordmans is nog in actieve
      ontwikkeling. Als u uw e-mailadres achterlaat zullen wij u af en toe op de
      hoogte houden.
    </p>

    <p>
      <input
        v-model="email"
        autocomplete="email"
        type="email"
        required
        class="block w-full bg-stone-200 px-3 py-1 dark:bg-gray-500"
        placeholder="E-mailadres"
      />
    </p>

    <p v-if="message" class="text-base italic">{{ message }}</p>

    <button class="underline underline-offset-2">Aanmelden</button>
  </form>
</template>

<script setup lang="ts">
  import { reactive, ref } from 'vue'
  import { useMutation } from 'villus'

  import CreateSubscription from '../graphql/CreateSubscription.graphql'

  const email = ref('')
  const variables = reactive({ email })
  const message = ref<null | string>(null)

  const { execute } = useMutation(CreateSubscription)

  const createSubscription = () => {
    execute(variables).then(({ data, error }) => {
      email.value = ''

      if (error) {
        message.value = (error.graphqlErrors || []).join(', ')
      } else {
        email.value = ''
        message.value = `Aangemeld voor de ${data.createSubscription.list}-nieuwsbrief.`
      }
    })
  }
</script>
