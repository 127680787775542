import { createRouter, createWebHistory } from 'vue-router'
import Start from '../views/StartView.vue'

const routes = [
  {
    path: '/',
    name: 'start',
    component: Start,
  },
  {
    path: '/publicaties',
    name: 'books',
    component: () =>
      import(/* webpackChunkName: "books" */ '../views/BooksView.vue'),
  },
  {
    path: '/publicaties/:id',
    name: 'document',
    component: () =>
      import(/* webpackChunkName: "document" */ '../views/DocumentView.vue'),
    props: true,
  },
  {
    path: '/account',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/AccountView.vue'),
  },
  {
    path: '/project',
    name: 'project',
    component: () =>
      import(/* webpackChunkName: "project" */ '../views/ProjectView.vue'),
  },
  {
    path: '/:slug',
    name: 'page',
    component: () =>
      import(/* webpackChunkName: "page" */ '../views/PageView.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
