<template>
  <div class="py-4 sm:py-8">
    <h2 v-if="loadingTitle" class="text-3xl font-bold">{{ loadingTitle }}</h2>
    <p v-if="show">Laden…</p>
  </div> 
</template>

<script lang="ts" setup>
  import { ref } from 'vue'

  defineProps({
    loadingTitle: {
      type: String,
      default: () => null,
    },
  })

  const show = ref(false)

  setTimeout(() => (show.value = true), 1500)
</script>
