<template>
  <div class="space-y-4">
    <h3 class="text-xl font-bold">Laatste reacties</h3>

    <div v-if="isDone && data?.recentNotes.length" class="space-y-4">
      <div
        v-for="note in data.recentNotes"
        :key="`recent-note-${note.id}`"
        class="mb-6 space-y-2 border-b-2 border-dotted border-b-white pb-5 last:mb-0 last:border-b-0 last:pb-0 dark:border-b-gray-800"
      >
        <multiple-lines :lines="note.lines" :document="note.document" />

        <p class="text-base">
          <strong>{{ note.user.name }}:</strong>
          {{ truncateBody(note.body) }}
        </p>
      </div>
    </div>

    <template v-else>
      <p>Er zijn nog geen reacties geplaatst.</p>
    </template>
  </div>
</template>

<script lang="ts" setup>
  import { useQuery } from 'villus'
  import MultipleLines from '../components/MultipleLines.vue'
  import query from '../graphql/RecentNotes.graphql'

  const { data, isDone } = useQuery({ query, cachePolicy: 'network-only' })

  const truncateBody = (body: string) => {
    const wordCount = 15,
      allWords = body.split(' '),
      words = allWords.slice(0, wordCount) || [],
      lastWord = words.at(-1) || '',
      lastLetter = lastWord.slice(-1)

    if (allWords.length > wordCount) {
      let truncBody = words.join(' ')
      if (lastLetter !== '.') {
        truncBody += '…'
      }

      return truncBody
    } else {
      return body
    }
  }
</script>
