<template>
  <figure
    class="mb-8 max-w-[17rem] border border-stone-100 bg-white p-2 transition-all duration-300 dark:border-gray-800 dark:bg-gray-300"
    :class="{ '-rotate-2 shadow-xl': loaded }"
  >
    <img class="h-72 w-full object-cover" src="../assets/photo.jpg" alt="Foto van Oepke Noordmans" width="100%" height="100%" />

    <div class="mx-auto mt-3 w-3/4">
      <img src="../assets/autograph.png" alt="Handtekening van Oepke Noordmans" height="50" width="227" />
    </div>
  </figure>
</template>

<script setup lang="ts">
  import { onMounted, ref } from 'vue'

  const loaded = ref(false)
  onMounted(() => setTimeout(() => (loaded.value = true), 50))
</script>
