import { createApp } from 'vue'
import { createHead } from 'unhead'
import { createClient, defaultPlugins, ClientPluginContext } from 'villus'
import * as Sentry from "@sentry/vue";

import './style.css'
import router from './router'

import App from './App.vue'

import 'share-api-polyfill'

const garamondRegular = new FontFace(
  'EB Garamond',
  'url(/fonts/garamond-regular.ttf)',
  { weight: '400' }
)
const garamondBold = new FontFace(
  'EB Garamond',
  'url(/fonts/garamond-bold.ttf)',
  { weight: '700' }
)
const garamondItalic = new FontFace(
  'EB Garamond',
  'url(/fonts/garamond-italic.ttf)',
  { style: 'italic', weight: '400' }
)

// @ts-ignore
document.fonts.add(garamondRegular)
// @ts-ignore
document.fonts.add(garamondBold)
// @ts-ignore
document.fonts.add(garamondItalic)

const authPlugin = ({ opContext }: ClientPluginContext) => {
  opContext.credentials = 'include'
}

const apiRoot = import.meta.env.VITE_APP_API_ROOT as string,
  url = `${apiRoot}/api`

const client = createClient({
  url,
  use: [authPlugin, ...defaultPlugins()],
})

const app = createApp(App)
createHead()

Sentry.init({
  app,
  environment: import.meta.env.PROD ? 'production' : 'development',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api.stichtingnoordmans\.nl\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.2, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)
app.use(client)

app.mount('#app')
