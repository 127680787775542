<template>
  <a class="w-4 cursor-pointer" @click="executeSync">
    <exclamation-circle-icon v-if="failed" />
    <check-circle-icon v-else-if="success" />
    <arrow-path-icon v-else :class="{ 'animate-spin': isFetching }" />
  </a>

  <div
    v-if="msg"
    class="fixed right-2 top-2 z-30 rounded-md bg-black/90 px-2 py-1 font-sans text-sm text-white"
  >
    {{ msg }}
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useMutation } from 'villus'

  import SyncReadwise from '../graphql/SyncReadwise.graphql'
  import {
    ArrowPathIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
  } from '@heroicons/vue/24/outline'

  const props = defineProps({
    doSync: {
      type: Boolean,
      required: true,
    },
  })

  const success = ref(false)
  const failed = ref(false)
  const msg = ref<null | string>(null)

  const syncTimeout = ref(0)

  watch(props, ({ doSync }) => {
    if (doSync) {
      clearTimeout(syncTimeout.value)
      syncTimeout.value = setTimeout(executeSync, 5000)
    }
  })

  const { execute, isFetching } = useMutation(SyncReadwise)
  const executeSync = () => {
    execute().then(({ data, error }) => {
      if (error) {
        failed.value = true
        msg.value = `Readwise mislukt`
      } else {
        success.value = true
        msg.value = `Readwise +${data.syncReadwise.count}`
      }

      setTimeout(() => {
        success.value = false
        failed.value = false
        msg.value = null
      }, 2500)
    })
  }
</script>
