<template>
  <span
    v-if="showHighlight"
    class="absolute -ml-2 -mt-1 h-3 w-3 cursor-pointer"
    @click="showHighlight = false"
  >
    <span
      class="animate-highlight-ping absolute h-full w-full rounded-full bg-red-500 opacity-75"
    />
    <span class="absolute h-3 w-3 rounded-full bg-red-500" />
  </span>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  const showHighlight = ref(true)
</script>
