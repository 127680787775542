<template>
  <div class="space-y-2">
    <h3 class="text-xl font-bold">Uitgelicht</h3>

    <template v-if="isDone">
      <div v-if="data?.highlightedDocument">
        <p>
          Graag brengen wij
          <em>{{ data.highlightedDocument.title }}</em>
          onder de aandacht.
        </p>

        <p>
          <router-link
            class="inline-block underline underline-offset-2"
            :to="{
              name: 'document',
              params: { id: data.highlightedDocument.id },
            }"
            @click="
              emit('update:loading-title', data.highlightedDocument.title)
            "
          >
            Lees en denk mee
          </router-link>
        </p>
      </div>

      <div v-else>Geen uitgelicht document.</div>
    </template>

    <loading-view v-else />
  </div>
</template>

<script lang="ts" setup>
  import { useQuery } from 'villus'
  import LoadingView from '../components/LoadingView.vue'
  import query from '../graphql/HighlightedDocument.graphql'

  const emit = defineEmits(['update:loading-title'])

  const { data, isDone } = useQuery({ query })
</script>
