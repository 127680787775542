<template>
  <header
    ref="header"
    class="bg-stone-200 dark:bg-gray-800 dark:text-gray-300 sm:relative sm:z-30"
    :class="{ 'bg-stone-200/95 dark:bg-gray-900/85': isCollapsed }"
  >
    <div
      :class="{ 'pointer-events-auto opacity-100': isCollapsed }"
      class="pointer-events-none fixed left-0 top-0 z-30 w-full bg-inherit p-2 text-center opacity-0 transition-opacity duration-150 backdrop-blur-lg"
    >
      <h1 class="text-center text-3xl font-bold">
        <router-link to="/">Oepke Noordmans</router-link>
      </h1>
    </div>

    <div class="space-y-2 px-4 py-5 sm:px-8 sm:text-center">
      <h1 class="text-3xl font-bold sm:text-4xl">
        <router-link to="/">Oepke Noordmans</router-link>
      </h1>

      <nav>
        <ul
          class="inline-flex flex-wrap gap-1 text-xl [&>li:not(:last-child)]:after:ml-1 [&>li:not(:last-child)]:after:content-['–'] [&>li>a]:text-inherit"
        >
          <li id="home">
            <router-link to="/">Welkom</router-link>
          </li>
          <li>
            <router-link to="/publicaties">Publicaties</router-link>
          </li>
          <li>
            <router-link to="/informatie">Stichting</router-link>
          </li>
          <li>
            <router-link to="/word-donateur">Word donateur</router-link>
          </li>
          <li v-if="me" class="flex items-center gap-1">
            <router-link :to="{ name: 'account' }">{{ me.name }}</router-link>

            <readwise-controls
              v-if="me.hasReadwise"
              :do-sync="doSyncReadwise"
            />
          </li>
          <li v-else>
            <router-link :to="{ name: 'account' }">Inloggen</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script lang="ts" setup>
  import { onMounted, PropType, ref } from 'vue'
  import ReadwiseControls from '../components/ReadwiseControls.vue'

  defineProps({
    doSyncReadwise: {
      type: Boolean,
      required: true,
    },

    me: {
      type: Object as PropType<User | null>,
      default: () => null,
    },

    isCollapsed: {
      required: true,
      type: Boolean,
    },
  })

  const emit = defineEmits(['update:height'])
  const header = ref(null)

  const resizeObserver = new ResizeObserver(() => {
    const headerHeight = (header.value || { offsetHeight: 0 }).offsetHeight
    emit('update:height', headerHeight)
  })

  onMounted(() => {
    if (header.value) {
      resizeObserver.observe(header.value)
    }
  })
</script>

<style scoped lang="postcss">
  li:not(#home) .router-link-active {
    @apply underline underline-offset-2;
  }
</style>
